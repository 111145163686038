import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Snackbar from '@material-ui/core/Snackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { deletePolicy } from '../../graphql/queries';
import TooltipElement from './tooltipElement';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '20px auto',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  element: {
    padding: '0 16px',
    fontSize: '16px',
  },
  overflowMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '160px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      maxWidth: '95px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideMobileCol: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  linkBTN: {
    fontSize: '16px',
    padding: '16px 0 16px 16px',
    backgroundColor: '#FFF !important',
    borderRadius: '4px',
    color: 'var(--amplify-primary-color)',
    fontWeight: 'bold',
    '& span': {
      marginLeft: '4px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 16px 3px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '26px',
    },
  },
  linkDelBTN: {
    fontSize: '16px',
    padding: '16px 0 16px 16px',
    backgroundColor: '#FFF !important',
    borderRadius: '4px',
    color: '#f44336',
    fontWeight: 'bold',
    '& span': {
      marginLeft: '4px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 16px 3px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30px',
    },
  },
  snack: {
    '& .MuiSnackbarContent-root': {
      boxShadow: 'none',
    },
  },
}));

function PrivacyListing(props) {
  const classes = useStyles();
  const {
    policies,
    oldPolicies,
    dispatch,
    inSearch,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [delPolicyName, setDelPolicyName] = useState('');
  const [delPolicyPK, setDelPolicyPK] = useState(null);

  // Handle Snackbar copy policy link
  const handleClick = (message) => {
    setSnackMessage(message);
    setOpenSnackbar(true);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  // Handle Dialog delete policy
  const handleClickOpen = (name, pk) => {
    setDelPolicyName(name);
    setDelPolicyPK(pk);
    setOpenDialog(true);
  };
  const handleClickClose = () => {
    setOpenDialog(false);
  };
  const deleteItem = async () => {
    await API.graphql(
      graphqlOperation(deletePolicy, { pk: delPolicyPK }),
    );
    const localPolicies = [...policies];
    const oldLocalPolicies = [...oldPolicies];
    const pos = localPolicies.map((e) => e.pk).indexOf(delPolicyPK);
    localPolicies.splice(pos, 1);
    if (inSearch) {
      const oldPos = oldLocalPolicies.map((e) => e.pk).indexOf(delPolicyPK);
      oldLocalPolicies.splice(oldPos, 1);
    }
    dispatch({ type: 'policyDelete', privacies: localPolicies, oldPrivacies: oldLocalPolicies });
  };
  const handleClickDelete = (message) => {
    setSnackMessage(message);
    deleteItem();
    handleClickClose();
    setOpenSnackbar(true);
  };

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table aria-label="Listato Privacy">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.bold}>Ragione Sociale</TableCell>
            <TableCell align="left" className={classes.bold}>Template</TableCell>
            <TableCell align="left" className={`${classes.bold} ${classes.hideMobileCol}`}>ID</TableCell>
            <TableCell align="center" className={classes.bold}>Data</TableCell>
            <TableCell align="right" className={classes.bold}>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.map((privacy) => (
            <TableRow key={privacy.pk}>
              <TableCell align="left" className={classes.element}>
                <TooltipElement
                  extraBusinessNames={privacy.extraBusinessNames}
                  mainBusinessName={privacy.name}
                />
              </TableCell>
              <TableCell align="left" className={`${classes.element}`}>
                <span>
                  {privacy.agencyName ? privacy.agencyName : 'ICTandStrategy'}
                </span>
              </TableCell>
              <TableCell align="left" className={`${classes.element} ${classes.hideMobileCol}`}>
                <span className={classes.overflowMobile}>
                  {privacy.pk}
                </span>
              </TableCell>
              <TableCell align="center" className={`${classes.element}`}>
                <span>
                  {privacy.createDate ? privacy.createDate : '-'}
                </span>
              </TableCell>
              <TableCell align="right" className={classes.element}>
                <IconButton
                  className={classes.linkDelBTN}
                  onClick={() => { handleClickOpen(privacy.name, privacy.pk); }}
                >
                  <DeleteOutlineIcon />
                  <span className={classes.hideMobile}>Elimina</span>
                </IconButton>
                <CopyToClipboard text={privacy.canonical ? privacy.canonical : privacy.link} onCopy={() => { handleClick('Link copiato negli appunti'); }}>
                  <IconButton className={classes.linkBTN}>
                    <FileCopyOutlinedIcon />
                    <span className={classes.hideMobile}>Copia</span>
                  </IconButton>
                </CopyToClipboard>
                <IconButton className={classes.linkBTN} href={privacy.canonical ? privacy.canonical : privacy.link} target="_blank">
                  <OpenInNewIcon />
                  <span className={classes.hideMobile}>Apri</span>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackMessage}
        key="bottomcenter"
        className={classes.snack}
      />
      <Dialog
        open={openDialog}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Elimina policy &quot;
          {delPolicyName}
          &quot;
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cliccando su elimina cancellerai la policy definitivamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Annulla
          </Button>
          <Button onClick={() => { handleClickDelete('Policy eliminata definitivamente'); }} color="secondary" autoFocus>
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
PrivacyListing.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.object),
  oldPolicies: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  inSearch: PropTypes.bool,
};
PrivacyListing.defaultProps = {
  policies: [],
  oldPolicies: [],
  dispatch: () => {},
  inSearch: false,
};

export default PrivacyListing;
