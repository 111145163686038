import React, { useState } from 'react';
import { Link } from '@reach/router';
import { API, graphqlOperation } from 'aws-amplify';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CreatePolicy } from '../../graphql/queries';
import PolicyRecap from './policyRecap';
import FormFields from './formFields';
import CustomPrivacyTextFields from './customPrivacyTextFields';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px',
  },
  halfWidthLeft: {
    width: 'calc(50% - 12px)',
    margin: '12px 12px 12px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '12px 0',
    },
  },
  halfWidthRight: {
    width: 'calc(50% - 12px)',
    margin: '12px 0 12px 12px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '12px 0',
    },
  },
  confirmBTN: {
    height: '56px',
    fontSize: '16px',
    marginLeft: '24px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginTop: '24px',
      width: '100%',
    },
  },
  textBTN: {
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    marginBottom: '-10px',
    color: 'var(--amplify-primary-color)',
    position: 'relative',
    bottom: '-2px',
    textTransform: 'inherit',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  bottomGapXS: {
    borderRadius: '4px',
    paddingLeft: '6px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '44px',
    },
  },
  colOne: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
    },
  },
  alignLeft: {
    textAlign: 'left',
    marginBottom: '44px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
    },
  },
  alignRight: {
    textAlign: 'right',
    marginTop: '12px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
    },
  },
  circleLoad: {
    marginBottom: '-15px',
  },
  sectionTitle: {
    fontSize: '20px',
    margin: '0 0 24px 0',
  },
  linkBTN: {
    fontSize: '16px',
    padding: '16px 0 16px 16px',
    backgroundColor: '#FFF !important',
    borderRadius: '4px',
    color: 'var(--amplify-primary-color)',
    marginTop: '8px',
    fontWeight: 'bold',
    '& span': {
      marginLeft: '4px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
    },
  },
  snack: {
    '& .MuiSnackbarContent-root': {
      boxShadow: 'none',
    },
  },
  bucketErrCont: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: '#f44336',
      fontWeight: 'bold',
    },
  },
}));

function Form() {
  const classes = useStyles();

  const [snackPosition, setSnackPosition] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = snackPosition;

  const [state, setState] = useState({
    completed: 0,
    createdLink: [],
    disableControls: false,
    disableAddFields: true,
    error: false,
    agencyName: 'ICTandStrategy',
    formBlocks: [
      {
        id: 0,
        businessName: '',
        email: '',
        contactDpo: '',
        address: '',
        errBusinessName: false,
        errEmail: false,
        errAddress: false,
        errContactDpo: false,
      },
    ],
    customPolicyText:
    {
      ownersPrivacyTreatment: '',
      ownersLegalTreatmentBasis: '',
      ownersDataRetentionTime: '',
      companyPrivacyTreatment: '',
      companyLegalTreatmentBasis: '',
      companyDataRetentionTime: '',
    },
  });
  const {
    completed,
    createdLink,
    disableControls,
    disableAddFields,
    error,
    agencyName,
    formBlocks,
    customPolicyText,
  } = state;

  const handleClick = (newState) => () => {
    setSnackPosition({ open: true, ...newState });
  };

  const handleSelectChange = (e) => {
    setState({ ...state, agencyName: e.target.value });
  };

  const handleClose = () => {
    setSnackPosition({ ...snackPosition });
    setSnackPosition({ ...snackPosition, open: false });
    setState({ ...state, error: false });
  };

  const addAgencyButtonStatus = (lastBlock) => {
    if (lastBlock.businessName
        && lastBlock.email
        && lastBlock.address) {
      return false;
    }
    return true;
  };

  const unlockAddAgencyButton = () => {
    const lastBlock = formBlocks[formBlocks.length - 1];
    setState({ ...state, disableAddFields: addAgencyButtonStatus(lastBlock) });
  };

  const changeBlockFields = (id, errParam, errParamValue, param, paramValue) => {
    if (param) {
      setState((prevState) => ({
        ...prevState,
        formBlocks: prevState.formBlocks.map(
          (obj) => (obj.id === id ? Object.assign(obj, {
            [param]: paramValue, [errParam]: errParamValue,
          }) : obj),
        ),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        formBlocks: prevState.formBlocks.map(
          (obj) => (obj.id === parseInt(id, 10) ? Object.assign(obj, {
            [errParam]: errParamValue,
          }) : obj),
        ),
      }));
    }
    unlockAddAgencyButton();
  };

  const changeCustomPolicyText = (id, text) => {
    setState((prevState) => ({
      ...prevState,
      customPolicyText: {
        ...prevState.customPolicyText,
        [id]: text,
      },
    }
    ));
  };

  const sanitizeBusinessNameErr = () => {
    const BreakException = {};
    try {
      formBlocks.forEach((block) => {
        let localBusinessName = block.businessName;
        localBusinessName = localBusinessName.substring(0, 11).replace(/[^a-z0-9]/gi, '');
        if (!localBusinessName) {
          changeBlockFields(block.id, 'errBusinessName', true);
          throw BreakException;
        }
      });
    } catch (e) {
      return true;
    }
    return false;
  };

  const sanitizeEmailErr = () => {
    const BreakException = {};
    try {
      formBlocks.forEach((block) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(block.email).toLowerCase())) {
          changeBlockFields(block.id, 'errEmail', true);
          throw BreakException;
        }
      });
    } catch (e) {
      return true;
    }
    return false;
  };

  const sanitizeAddressErr = (dpo = false) => {
    const BreakException = {};
    try {
      formBlocks.forEach((block) => {
        const localAddress = dpo ? block.addressDpo : block.address;
        const localErr = dpo ? 'errAddressDpo' : 'errAddress';
        if (localAddress.length === 0) {
          changeBlockFields(block.id, localErr, true);
          throw BreakException;
        }
      });
    } catch (e) {
      return true;
    }
    return false;
  };

  const addBlock = () => {
    if (
      !sanitizeBusinessNameErr()
      && !sanitizeEmailErr()
      && !sanitizeAddressErr()
    ) {
      setState({
        ...state,
        disableAddFields: true,
        formBlocks: [...formBlocks, {
          id: formBlocks.length,
          businessName: '',
          email: '',
          contactDpo: '',
          address: '',
          errBusinessName: false,
          errEmail: false,
          errContactDpo: false,
          errAddress: false,
        }],
      });
    }
  };

  async function addPolicy(event) {
    event.preventDefault();
    if (
      !sanitizeBusinessNameErr()
      && !sanitizeEmailErr()
      && !sanitizeAddressErr()
    ) {
      try {
        setState({ ...state, disableControls: true });
        const readyForSubmitFields = formBlocks.map((block) => ({
          businessName: block.businessName,
          email: block.email,
          contactDpo: block.contactDpo,
          address: block.address,
        }));
        const createdElement = await API.graphql(
          graphqlOperation(CreatePolicy, {
            agencyName,
            readyForSubmitFields,
            customPolicyText,
          }),
        );
        setState({ ...state, completed: 1, createdLink: createdElement.data.addPrivacy.canonical });
      } catch (err) {
        switch (err.errors[0].message) {
          case '"Empity BusinessName"': changeBlockFields(err.errors[0].errorInfo.index, 'errBusinessName', true); break;
          case '"Wrong Email"': changeBlockFields(err.errors[0].errorInfo.index, 'errEmail', true); break;
          case '"Empity Address"': changeBlockFields(err.errors[0].errorInfo.index, 'errAddress', true); break;
          case '"Write on Bucket error"': setState({ ...state, error: true }); break;
          default: throw new Error(err.errors[0].message);
        }
      }
    }
  }

  if (completed) {
    return (
      <Paper className={classes.container}>
        <h1 className={classes.sectionTitle}>Policy creata con successo!</h1>
        <PolicyRecap
          formBlocks={formBlocks}
          agencyName={agencyName}
          customPolicyText={customPolicyText}
        />
        <Grid container spacing={0}>
          <Grid item xs={6} className={classes.colOne}>
            <CopyToClipboard text={createdLink} onCopy={handleClick({ vertical: 'bottom', horizontal: 'center' })}>
              <IconButton className={classes.linkBTN}>
                <FileCopyOutlinedIcon />
                <span>Copia Link</span>
              </IconButton>
            </CopyToClipboard>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              autoHideDuration={5000}
              message="Link copiato negli appunti"
              key="bottomcenter"
              className={classes.snack}
            />
            <IconButton className={classes.linkBTN} href={createdLink} target="_blank">
              <OpenInNewIcon />
              <span>Apri in un&apos;altra scheda</span>
            </IconButton>
          </Grid>
          <Grid item xs={6} className={classes.alignRight}>
            <Button className={classes.confirmBTN} component={Link} to="/" variant="contained" color="primary">
              Torna all&apos;elenco
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.container}>
      <h1 className={classes.sectionTitle}>Aggiungi policy</h1>
      <form validate="true" autoComplete="on" onSubmit={addPolicy}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Template</InputLabel>
          <Select
            value={agencyName}
            onChange={(e) => handleSelectChange(e)}
            label="Template"
          >
            <MenuItem value="ICTandStrategy">ICTandStrategy</MenuItem>
            <MenuItem value="ICTandStrategyV01">ICTandStrategy Variante 1</MenuItem>
            <MenuItem value="ICTandStrategyEn">ICTandStrategy English</MenuItem>
          </Select>
        </FormControl>
        <FormFields
          formBlocks={formBlocks}
          changeBlockFields={changeBlockFields}
        />
        <div className={classes.alignLeft}>
          <IconButton className={`${classes.textBTN} ${classes.bottomGapXS}`} onClick={addBlock} disabled={disableAddFields}>
            <AddIcon />
            Aggiungi azienda
          </IconButton>
        </div>
        {agencyName === 'ICTandStrategy' && (
          <div>
            <CustomPrivacyTextFields
              changeCustomPolicyText={changeCustomPolicyText}
            />
          </div>
        )}
        <div className={classes.alignRight}>
          {disableControls ? <CircularProgress className={classes.circleLoad} /> : <Link className={classes.textBTN} to="/">ANNULLA</Link>}
          <Button type="submit" className={classes.confirmBTN} variant="contained" color="primary" disabled={disableControls}>
            Crea Privacy Policy
          </Button>
        </div>
      </form>
      <div className={classes.bucketErrCont}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={error}
          onClose={handleClose}
          autoHideDuration={5000}
          message="Errore creazione della Policy. Riprova più tardi."
          key="bottomcenter"
          elevation={5}
          className={classes.snack}
        />
      </div>
    </Paper>
  );
}

export default Form;
