const listPolicies = `
  query listPoliciesQuery($token: String, $searchText: String) {
    listPolicies(nextToken: $token, searchText: $searchText) {
      privacies {
        businessName
        link
        canonical
        name
        pk
        sk
        extraBusinessNames
        agencyName
        createDate
      }
      nextToken
    }
  }
`;

const CreatePolicy = `
  mutation CreatePolicyMutation($readyForSubmitFields: [InputPrivacy]!, $agencyName: String, $customPolicyText: CustomPolicyText ) {
    addPrivacy(input: $readyForSubmitFields, agencyName: $agencyName, customPolicyText: $customPolicyText) {
      businessName
      extraBusinessNames
      link
      canonical
      name
      nameS
      pk
      sk
      agencyName
    }
  }
`;

const deletePolicy = `
  mutation deletePolicyMutation($pk: String!) {
    delPrivacy(input: {pk: $pk}) {
      pk
    }
  }
`;

const searchPolicy = `
  query searchPoliciesQuery($from: Int, $searchText: String) {
    searchPolicies(from: $from, searchText: $searchText) {
      privacies {
        businessName
        link
        canonical
        name
        pk
        sk
        extraBusinessNames
        agencyName
        createDate
      }
      total
    }
  }
`;

module.exports = {
  listPolicies, CreatePolicy, deletePolicy, searchPolicy,
};
