import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  loadMoreBTN: {
    margin: '24px 0',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'var(--amplify-primary-color)',
    cursor: 'pointer',
  },
});

function BottomArea(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.loadMoreBTN} {...props}>
        MOSTRA ALTRE
      </span>
    </div>
  );
}

export default BottomArea;
