import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleBar from '../titleBar';
import Form from './formArea';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '20px auto',
    padding: '0 16px',
  },
});

function NewPolicy() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TitleBar />
      <Form />
    </div>
  );
}

export default NewPolicy;
