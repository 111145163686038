import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolLine: {
    fontSize: '0.8rem',
  },
}));

function MultilineBusinessNames(props) {
  const classes = useStyles();
  const {
    lines,
  } = props;
  return (
    <div>
      {lines.map((line) => (
        <div key={line} className={classes.toolLine}>
          {line}
        </div>
      ))}
    </div>
  );
}
MultilineBusinessNames.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.string),
};
MultilineBusinessNames.defaultProps = {
  lines: [],
};

export default MultilineBusinessNames;
