import React, { useState, useEffect } from 'react';

import Amplify, { I18n, Hub, API } from 'aws-amplify';
import {
  AmplifyAuthenticator, AmplifySignIn, AmplifyForgotPassword,
} from '@aws-amplify/ui-react';

import { AuthState, Translations } from '@aws-amplify/ui-components';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

import { Router } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';

import awsconfig from './aws-exports';

import Home from './components/home/main';
import NewPolicy from './components/newPolicy/main';

I18n.putVocabulariesForLanguage('it-IT', {
  [Translations.FORGOT_PASSWORD_TEXT]: 'Password dimenticata?',
  [Translations.RESET_PASSWORD_TEXT]: 'Reimpostala qui',
  [Translations.CODE_LABEL]: 'Codice di verifica',
  [Translations.BACK_TO_SIGN_IN]: 'Torna al login',
  [Translations.CHANGE_PASSWORD]: 'Cambia Password',
  [Translations.NEW_PASSWORD_LABEL]: 'Nuova Password',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Inserisci una nuova Password',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Cambia',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Verifica le seguenti informazioni',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verifica',
  [Translations.CODE_PLACEHOLDER]: 'Inserisci codice',
  [Translations.SUBMIT]: 'Verifica',
  [Translations.DEFAULT_MSG]: 'Errore di Autenticazione',
  [Translations.EMPTY_USERNAME]: 'Il campo Email non può essere vuoto',
  [Translations.EMPTY_PASSWORD]: 'Il campo Password non può essere vuoto',
  [Translations.EMPTY_CODE]: 'Il campo relativo al codice di conferma non può essere vuoto',
  [Translations.USERNAME_PLACEHOLDER]: 'Inserisci la tua email',
  [Translations.USERNAME_LABEL]: 'Email *',
  'User does not exist.': 'L\'utente non esiste.',
});

Amplify.configure(awsconfig);

const privacyTheme = createMuiTheme({
  palette: {
    background: {
      default: '#f5f5f5',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          '--amplify-primary-color': '#3f51b5',
          '--amplify-primary-tint': '#838fd6',
          '--amplify-primary-shade': '#3f51b5',
          '--amplify-secondary-tint': 'var(--amplify-primary-color)',
          '--amplify-red': 'rgba(255, 255, 255, 0.7);',
        },
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    '& amplify-sign-in, & amplify-forgot-password': {
      '&::before': {
        content: '"Portale Privacy Custom"',
        fontSize: '24px',
        lineHeight: '32px',
        display: 'block',
        textAlign: 'center',
        margin: '24px 0',
        fontWeight: 'bold',
        color: 'var(--amplify-primary-color)',
      },
    },
  },
  thankYou: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& .MuiPaper-root': {
      padding: 'var(--padding, 35px 40px)',
      minWidth: 'var(--min-width, 20rem)',
      fontFamily: 'var(--font-family)',
      marginBottom: 'var(--margin-bottom, 20px)',
    },
    '& p': {
      fontSize: '16px',
    },
  },
  backLogin: {
    height: '56px',
  },
});

async function apiRequest(currentEmail) {
  await API.post(
    awsconfig.API.endpoints[0].name,
    '/force-reset-password',
    { body: { email: currentEmail } },
  );
}

function App() {
  const classes = useStyles();
  const [authState, setAuthState] = useState();
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordResetFail, setPasswordResetFail] = useState(false);

  const handleLogin = (nextAuthState) => {
    setAuthState(nextAuthState);
  };

  useEffect(() => {
    const passwordHandler = async () => {
      await Hub.listen('auth', async (data) => {
        const { payload } = data;
        if (payload.event === 'forgotPasswordSubmit') {
          setPasswordReset(true);
        }
        if ((payload.event === 'forgotPassword_failure') && (payload.data.code === 'NotAuthorizedException')) {
          const splittedMessage = payload.message.split(' ');
          const currentEmail = splittedMessage[0];
          apiRequest(currentEmail);
          setPasswordResetFail(true);
        }
      });
    };
    passwordHandler();
  }, []);

  if (authState === AuthState.SignedIn) {
    return (
      <MuiThemeProvider theme={privacyTheme}>
        <CssBaseline />
        <Router>
          <Home path="/" />
          <NewPolicy path="/newpolicy" />
        </Router>
      </MuiThemeProvider>
    );
  }
  if (passwordReset) {
    return (
      <MuiThemeProvider theme={privacyTheme}>
        <CssBaseline />
        <div className={classes.thankYou}>
          <Paper>
            <h3 className="header">Password reimpostata</h3>
            <p>
              La tua password è stata reimpostata con successo.
              <br />
              Torna alla pagina di Login ed effettua l&apos;accesso:
            </p>
            <center>
              <Button onClick={() => { window.location.reload(); }} className={classes.backLogin} to="newpolicy" variant="contained" color="primary">
                Torna al Login
              </Button>
            </center>
          </Paper>
        </div>
      </MuiThemeProvider>
    );
  }
  if (passwordResetFail) {
    return (
      <MuiThemeProvider theme={privacyTheme}>
        <CssBaseline />
        <div className={classes.thankYou}>
          <Paper>
            <h3 className="header">Reimposta password</h3>
            <p>
              La tua password temporanea è stata inviata al tuo indirizzo email.
              <br />
              Torna alla pagina di Login ed effettua l&apos;accesso:
            </p>
            <center>
              <Button onClick={() => { window.location.reload(); }} className={classes.backLogin} to="newpolicy" variant="contained" color="primary">
                Torna al Login
              </Button>
            </center>
          </Paper>
        </div>
      </MuiThemeProvider>
    );
  }
  return (
    <MuiThemeProvider theme={privacyTheme}>
      <CssBaseline />
      <AmplifyAuthenticator handleAuthStateChange={handleLogin} className={classes.root}>
        <AmplifySignIn
          headerText="Accedi"
          hideSignUp="true"
          submitButtonText="Login"
          slot="sign-in"
          formFields={[
            {
              type: 'email',
              label: 'Email',
              placeholder: 'Inserisci la tua Email',
              required: true,
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Inserisci la tua Password',
              required: true,
            },
          ]}
        />
        <AmplifyForgotPassword
          headerText="Reimposta password"
          sendButtonText="Invia codice"
          slot="forgot-password"
        />
      </AmplifyAuthenticator>
    </MuiThemeProvider>
  );
}

export default App;
