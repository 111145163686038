import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: 'flex',
  },
  fieldsBlock: {
    marginBottom: '32px',
  },
  sectionParagraph: {
    fontSize: '16px',
    margin: '0 0 24px',
  },
  sectionTitle: {
    fontSize: '20px',
    margin: '0',
  },
  flexItemWithMargin: {
    flex: 'auto',
    marginRight: '24px',
  },
  flexItemNoMargin: {
    flex: 'auto',
  },
}));

const CustomPrivacyTextFields = (props) => {
  const classes = useStyles();
  const { changeCustomPolicyText } = props;

  return (
    <div>
      <div className={classes.fieldsBlock}>
        <h1 className={classes.sectionTitle}>Titolari</h1>
        <p className={classes.sectionParagraph}>
          Qualora i seguenti campi non vengano compilati, verranno utilizzati i valori di default.
        </p>
        <div className={classes.flexContainer}>
          <TextField
            label="Per quale finalità i dati sono trattati dai Titolari?"
            className={classes.flexItemWithMargin}
            id="ownersPrivacyTreatment"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />
          <TextField
            label="Quale è la base giuridica che legittima il trattamento?"
            className={classes.flexItemWithMargin}
            id="ownersLegalTreatmentBasis"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />

          <TextField
            label="Per quanto tempo sono conservati i dati?"
            className={classes.flexItemNoMargin}
            id="ownersDataRetentionTime"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />
        </div>
      </div>

      <div className={classes.fieldsBlock}>
        <h1 className={classes.sectionTitle}>Azienda</h1>
        <p className={classes.sectionParagraph}>
          Qualora i seguenti campi non vengano compilati, verranno utilizzati i valori di default.
        </p>
        <div className={classes.flexContainer}>
          <TextField
            label="Per quale finalità i dati sono trattati dalla Società?"
            className={classes.flexItemWithMargin}
            id="companyPrivacyTreatment"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />
          <TextField
            label="Quale è la base giuridica che legittima il trattamento?"
            className={classes.flexItemWithMargin}
            id="companyLegalTreatmentBasis"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />
          <TextField
            label="Per quanto tempo sono conservati i dati?"
            className={classes.flexItemNoMargin}
            id="companyDataRetentionTime"
            variant="outlined"
            multiline
            rows={2}
            onChange={(event) => changeCustomPolicyText(event.target.id, event.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPrivacyTextFields;
