const awsmobile = {
	"aws_project_region": "eu-west-1", 
	"aws_cognito_region": "eu-west-1", 
	"aws_user_pools_id": "eu-west-1_ELZ7t0ALE", 
	"aws_user_pools_web_client_id": "6gt2gros35kidbcqrgs592hg92", 
	"aws_appsync_graphqlEndpoint": "https://7nhpmfnvozfdreq2vd2e2luy6i.appsync-api.eu-west-1.amazonaws.com/graphql", 
	"aws_appsync_region": "eu-west-1", 
	"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS", 
	"aws_appsync_apiKey": 'null', 
	"API": {
	  "endpoints": [
	   {
	    "name": "api",
	    "endpoint": "https://r4q9xeqk65.execute-api.eu-west-1.amazonaws.com/prod/",
	   },
	 ],
	},

}; 

export default awsmobile;