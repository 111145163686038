import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    margin: '0px auto 32px auto',
  },
  thankYouText: {
    fontSize: '16px',
    marginTop: '-12px',
  },
  policyData: {
    fontSize: '16px',
  },
});

function PolicyRecap(props) {
  const classes = useStyles();
  const {
    formBlocks,
    agencyName,
    customPolicyText,
  } = props;

  const isPolicyTextChanged = () => Object.values(customPolicyText).some((value) => value !== '');

  const subtititlePolicyText = {
    ownersPrivacyTreatment: 'Per quale finalità i dati sono trattati dai Titolari?',
    ownersLegalTreatmentBasis: 'Quale è la base giuridica che legittima il trattamento?',
    ownersDataRetentionTime: 'Per quanto tempo sono conservati i dati?',
    companyPrivacyTreatment: 'Per quale finalità i dati sono trattati dalla Società?',
    companyLegalTreatmentBasis: 'Quale è la base giuridica che legittima il trattamento?',
    companyDataRetentionTime: 'Per quanto tempo sono conservati i dati?',
  };

  return (
    <div className={classes.container}>
      <p className={classes.thankYouText}>
        Di seguito trovi i dettagli della Policy appena creata:
      </p>
      <p>
        <strong>Template utilizzato: </strong>
        <i>{agencyName}</i>
      </p>
      {formBlocks.map((block) => (
        <ul className={classes.policyData} key={block.businessName}>
          <li>
            <strong>Ragione Sociale: </strong>
            <i>{block.businessName}</i>
          </li>
          <li>
            <strong>Email: </strong>
            <i>{block.email}</i>
          </li>
          <li>
            <strong>Indirizzo: </strong>
            <i>{block.address}</i>
          </li>
          {block.contactDpo && (
          <li>
            <strong>Dati di contatto del DPO: </strong>
            <i>{block.contactDpo}</i>
          </li>
          )}
        </ul>
      ))}
      {isPolicyTextChanged() && (
        <div>
          <p>
            <strong>Campi testo policy personalizzati:</strong>
          </p>
          <ul>
            {Object.keys(customPolicyText).map((key) => (
              customPolicyText[key] !== '' && (
              <li>
                <strong>{`${subtititlePolicyText[key]}: `}</strong>
                {customPolicyText[key]}
              </li>
              )
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
PolicyRecap.propTypes = {
  formBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  agencyName: PropTypes.string.isRequired,
};

export default PolicyRecap;
