import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fieldsBlock: {
    marginBottom: '16px',
  },
  halfWidthLeft: {
    width: 'calc(50% - 12px)',
    margin: '12px 12px 12px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '12px 0',
    },
  },
  halfWidthRight: {
    width: 'calc(50% - 12px)',
    margin: '12px 0 12px 12px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '12px 0',
    },
  },
}));

function FormFields(props) {
  const classes = useStyles();
  const {
    formBlocks,
    changeBlockFields,
  } = props;

  return (
    <div>
      {formBlocks.map((block) => (
        <div id={`azienda-${block.id}`} key={`azienda-${block.id}`} className={classes.fieldsBlock}>
          <TextField
            error={block.errBusinessName}
            helperText={block.errBusinessName ? 'Il campo contiene solo caratteri speciali e non può essere vuoto' : ''}
            className={classes.halfWidthLeft}
            id="businessName"
            label="Ragione Sociale"
            variant="outlined"
            onChange={(event) => changeBlockFields(block.id, 'errBusinessName', false, 'businessName', event.target.value)}
          />
          <TextField
            error={block.errEmail}
            helperText={block.errEmail ? 'Il campo non contiene una email valida e non può essere vuoto' : ''}
            className={classes.halfWidthRight}
            id="email"
            type="email"
            label="Email"
            variant="outlined"
            onChange={(event) => changeBlockFields(block.id, 'errEmail', false, 'email', event.target.value)}
          />
          <TextField
            error={block.errAddress}
            helperText={block.errAddress ? 'Il campo non può essere vuoto' : ''}
            className={classes.halfWidthLeft}
            id="address"
            label="Indirizzo"
            variant="outlined"
            onChange={(event) => changeBlockFields(block.id, 'errAddress', false, 'address', event.target.value)}
          />
          <TextField
            error={block.errContactDpo}
            className={classes.halfWidthRight}
            id="contactDpo"
            type="text"
            label="Dati di contatto del DPO"
            variant="outlined"
            onChange={(event) => changeBlockFields(block.id, 'errContactDpo', false, 'contactDpo', event.target.value)}
          />
        </div>
      ))}
    </div>
  );
}
FormFields.propTypes = {
  formBlocks: PropTypes.arrayOf(PropTypes.object),
  changeBlockFields: PropTypes.func,
};
FormFields.defaultProps = {
  formBlocks: [],
  changeBlockFields: () => {},
};

export default FormFields;
