import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from '@reach/router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
    },
  },
  colOne: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  colTwo: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
      paddingTop: '20px',
    },
  },
  container: {
    marginBottom: '36px',
  },
  search: {
    width: '100%',
    padding: '14px 15px 10px 15px',
  },
  policyBTN: {
    height: '56px',
    borderRadius: '0 4px 4px 0',
    boxShadow: 'none',
  },
  addPolicyBTN: {
    height: '56px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

function HeadingArea(props) {
  const classes = useStyles();
  const {
    searchBusinessName, clearAll,
  } = props;
  const [inSearch, setInSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [lastSearch, setLastSearch] = useState('');

  const clearText = (event) => {
    event.preventDefault();
    setInSearch(false);
    setSearchText('');
    clearAll();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setInSearch(true);
    searchBusinessName(searchText);
    setLastSearch(searchText);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={0}>
        <Grid item xs={6} className={classes.colOne}>
          <Paper component="form" validate="true" autoComplete="off" onSubmit={(event) => onSubmit(event)} className={classes.root}>
            <InputBase required className={classes.search} value={searchText} placeholder="Cerca una ragione sociale" variant="outlined" onChange={(event) => setSearchText(event.target.value.toLowerCase())} inputProps={{ 'aria-label': 'Cerca una ragione sociale' }} />
            {inSearch ? (
              <IconButton className={classes.policyBTN} variant="contained" color="primary" onClick={(event) => clearText(event)}>
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton className={classes.policyBTN} variant="contained" color="primary" type="submit">
                <SearchIcon />
              </IconButton>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} align="right" className={classes.colTwo}>
          <Button className={classes.addPolicyBTN} component={Link} to="newpolicy" variant="contained" color="primary">
            <AddIcon />
            Aggiungi Policy
          </Button>
        </Grid>
      </Grid>
      {inSearch && (
        <p>
          Policy con ragione sociale che contiene:&nbsp;
          <strong>{lastSearch}</strong>
        </p>
      )}
    </div>
  );
}
HeadingArea.propTypes = {
  searchBusinessName: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default HeadingArea;
