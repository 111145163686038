import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MultilineBusinessNames from './multilineBusinessNames';

const useStyles = makeStyles((theme) => ({
  inlineCounter: {
    display: 'inline',
    cursor: 'default',
    position: 'relative',
    right: '-2px',
    top: '-1px',
    '&::before': {
      content: '"(+"',
    },
    '&::after': {
      content: '")"',
    },
  },
  flexDownSM: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  overflowMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '160px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      maxWidth: '95px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}));

function TooltipElement(props) {
  const classes = useStyles();
  const {
    extraBusinessNames,
    mainBusinessName,
  } = props;
  if (extraBusinessNames && extraBusinessNames.length !== 1) {
    return (
      <Tooltip title={<MultilineBusinessNames lines={extraBusinessNames} />} arrow placement="bottom-start">
        <div className={classes.flexDownSM}>
          <span className={classes.overflowMobile}>
            {mainBusinessName}
          </span>
          <span className={classes.inlineCounter}>
            {extraBusinessNames.length - 1}
          </span>
        </div>
      </Tooltip>
    );
  }
  return (
    <span className={classes.overflowMobile}>
      {mainBusinessName}
    </span>
  );
}
TooltipElement.propTypes = {
  extraBusinessNames: PropTypes.arrayOf(PropTypes.string),
  mainBusinessName: PropTypes.string,
};
TooltipElement.defaultProps = {
  extraBusinessNames: [],
  mainBusinessName: '',
};

export default TooltipElement;
