import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from '@reach/router';

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    throw new Error(error);
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '32px',
    marginBottom: '40px',
    '& a': {
      textDecoration: 'none',
    },
  },
  colOne: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '66%',
      flexBasis: '66%',
    },
  },
  colTwo: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '33%',
      flexBasis: '33%',
    },
  },
  titleSite: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: 'var(--amplify-primary-color)',
  },
  logoutBTN: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    margin: '16px 0',
    lineHeight: '32px',
    width: 'max-content',
    '& svg': {
      marginBottom: '-6px',
    },
  },
}));

function TitleBar() {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={6} className={classes.colOne}>
        <Link to="/">
          <h1 className={classes.titleSite}>Portale Privacy Custom</h1>
        </Link>
      </Grid>
      <Grid item xs={6} align="right" className={classes.colTwo}>
        <span onClick={signOut} onKeyDown={signOut} role="button" tabIndex={0} className={classes.logoutBTN}>
          Logout&nbsp;
          <ExitToAppIcon />
        </span>
      </Grid>
    </Grid>
  );
}

export default TitleBar;
