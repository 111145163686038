import React, { useEffect, useReducer, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import PrivacyListing from './privacyListing';
import TitleBar from '../titleBar';
import HeadingArea from './headingArea';
import BottomArea from './bottomArea';
import { listPolicies, searchPolicy } from '../../graphql/queries';

function reducer(listingParams, action) {
  const {
    type, privacies, token, clearSearchText, oldPrivacies,
  } = action;
  switch (type) {
    case 'policies_nextToken': return { ...listingParams, policies: listingParams.policies.concat(privacies), nextToken: token };
    case 'policiesClear': {
      const obj = {
        ...listingParams,
        policies: [],
        searchText: clearSearchText,
      };

      if (!listingParams.inSearch) {
        obj.oldPolicies = listingParams.policies;
        obj.oldNextToken = listingParams.nextToken;
        obj.inSearch = true;
      }

      return obj;
    }
    case 'policiesRewind': return {
      ...listingParams,
      policies: listingParams.oldPolicies,
      nextToken: listingParams.oldNextToken,
      searchText: '',
      inSearch: false,
    };
    case 'policyDelete': return {
      ...listingParams,
      policies: privacies,
      oldPolicies: oldPrivacies,
    };
    default: throw new Error();
  }
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '20px auto',
    padding: '0 16px',
  },
});

function Home() {
  const classes = useStyles();

  const [listingParams, dispatch] = useReducer(
    reducer, {
      policies: [],
      nextToken: undefined,
      searchText: undefined,
      oldPolicies: [],
      oldNextToken: undefined,
      inSearch: false,
    },
  );
  const {
    policies, nextToken, searchText, oldPolicies, inSearch,
  } = listingParams;

  const fetchPolicies = useCallback(
    async (newToken, text = '') => {
      try {
        let privacies;
        let token;

        if (text) {
          const policiesData = await API.graphql(
            graphqlOperation(searchPolicy, { from: newToken, searchText }),
          );
          privacies = policiesData.data.searchPolicies.privacies;
          token = policiesData.data.searchPolicies.total;
          token = (policies.length + privacies.length) === token ? undefined : privacies.length;
        } else {
          const policiesData = await API.graphql(
            graphqlOperation(listPolicies, { token: newToken }),
          );
          privacies = policiesData.data.listPolicies.privacies;
          token = policiesData.data.listPolicies.nextToken;
        }

        dispatch({ type: 'policies_nextToken', privacies, token });
      } catch (err) {
        throw new Error(err);
      }
    },
    [policies, nextToken, searchText],
  );

  const searchBusinessName = (text) => {
    dispatch({ type: 'policiesClear', clearSearchText: text });
  };
  useEffect(() => {
    if (searchText !== '') fetchPolicies(null, searchText);
  }, [searchText]);

  const clearAll = () => {
    dispatch({ type: 'policiesRewind' });
  };

  const loadMore = () => {
    fetchPolicies(nextToken, searchText);
  };

  return (
    <div className={classes.container}>
      <TitleBar />
      <HeadingArea searchBusinessName={searchBusinessName} clearAll={clearAll} />
      <PrivacyListing
        policies={policies}
        oldPolicies={oldPolicies}
        dispatch={dispatch}
        inSearch={inSearch}
      />
      {nextToken && <BottomArea onClick={loadMore} />}
    </div>
  );
}

export default Home;
